import classNames from "classnames";
import React, { PropsWithChildren } from "react";
import { ClassNameProp } from "../utils";

export type FormLabelProps = ClassNameProp;

const Label: React.FC<PropsWithChildren<FormLabelProps>> = ({
  children,
  className,
}) => {
  return <label className={classNames("label", className)}>{children}</label>;
};

export default Label;
