import React from "react";
import logo from "../../images/logo.jpg";

const Logo: React.FC = () => {
  return (
    <img
      width={100}
      height={109}
      alt="Clinical Assessment Body Alignment"
      src={logo}
    />
  );
};

export default Logo;
