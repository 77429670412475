import LabelGroups from "./_labels";

const pelvis = {
  name: "Pelvis",
  criteria: [
    {
      name: "Posterior / Anterior tilt",
      image: "/assessment-images/pelvis-flexion-extension.png",
      options: [
        {
          label: "Extension (Posterior)",
          values: LabelGroups.FIVE_TWENTY_SIXTY_PLUS,
        },
        {
          label: "Neutral",
          values: LabelGroups.NEUTRAL,
        },
        {
          label: "Flexion (Anterior)",
          values: LabelGroups.FIVE_TWENTY_SIXTY_PLUS,
        },
      ],
    },
    {
      name: "Obliquity",
      image: "/assessment-images/pelvis-side-flexion.png",
      options: [
        {
          label: "Right",
          values: LabelGroups.FIVE_TWENTY_SIXTY_PLUS,
        },
        {
          label: "Neutral",
          values: LabelGroups.NEUTRAL,
        },
        {
          label: "Left",
          values: LabelGroups.FIVE_TWENTY_SIXTY_PLUS,
        },
      ],
    },
    {
      name: "Rotation",
      image: "/assessment-images/pelvis-rotation.png",
      options: [
        {
          label: "Left",
          values: LabelGroups.FIVE_TWENTY_SIXTY_PLUS,
        },
        {
          label: "Neutral",
          values: LabelGroups.NEUTRAL,
        },
        {
          label: "Right",
          values: LabelGroups.FIVE_TWENTY_SIXTY_PLUS,
        },
      ],
    },
  ],
};

export default pelvis;
