const text = `
### Clinical Assessment of Body Alignment – CABA ©

#### What the CABA assessment does:

The CABA is a standardised assessment designed for therapists to observe and measure a change in a child’s body alignment as part of posture interventions in everyday clinical practice. The CABA measures and quantifies postural alignment.

The CABA is a simple, clinically applicable assessment tool. It has 20 items, split into 6 sections. It can be used to assess a set position such as sitting or give a complete picture of an individual’s alignment in sitting, lying and standing.

The CABA posture classifications used a 0-3 scoring system to rank the alignment with 0 indicating a position within 5 degrees, either side of optimal alignment, and three indicating the most significant deviation away from optimal alignment. All CABA items are based on this scoring system with the exception of four items, which score on a 0-2 scale in one movement direction, due to the limited joint range from optimal.

The CABA measure optimal body alignment in in lying, sitting and standing. It also measures deviation in postural alignment and can quantify this as a total score, by body segment, by a specific plan of movement and denotes which side of the body and the specific direction of movement.

A score of 0 is set as the optimal postural position for each position (sitting, lying and standing). This ensures the CABA can identify the position which minimising stress on body structures, gives a position of stability and maximises opportunities for efficient movement and function and measure deviation in this.

*Example:*

Below is item 8 on the CABA which measures pelvic obliquity. Depending on the amount of pelvic obliquity, this can be measured from a score of 0 = pelvis is level and neutral to a score of 3. This gives an accurate and consistent approach which can be repeated and compared, either to past assessments or between assessors.

![CABA example](/assessment-images/caba-help-example.jpg "CABA example")

CABA scoring uses a traffic light color system; Green indicated a optimal alignment, yellow indicates score 1, amber indicates score 2 and red indicates score 3. The traffic light system was applied to assist therapists in obtaining clear, clinically useful information and to facilitate ease of interpretation.

The scores can easily be outlined on a score sheet and visually on a posture picture.


#### How it is an outcome measure:

The CABA is a standardised assessment. It has demonstrated excellent validity, reliability and responsiveness properties. It is designed as a simple, quick assessment to use. It requires no equipment and can be applied easily in any setting, making assessment of postural equipment possible at any time in any place.

If you want to measure outcomes then you need standardised assessments. These are essential in ensuring clinicians can evidence the effectiveness and outcomes of their interventions. The CABA, by virtue of the psychometric properties examined in its development, is an effective standardsied measure in identifying clinically meaningful change of body alignment in children.

The CABA allows therapists to identify and examine specific areas where change in alignment has occurred, offering a comprehensive summary and comparison of results to previous assessment. This enables clinicians to measure the effectiveness of their posture management strategies in terms of managing body alignment and equipment provision.

The CABA provides an additional clinical dimension to the assessment of posture. Designed as a simple and clinically functional assessment it can support findings of more established, infrequent, standardised lab-based tests typically used to measure effect of posture such as x-rays. This enables therapists to quantify with meaningful consistency the results of the provision of positioning equipment, justifying clinical judgements on the impact and effectiveness this has on supporting and correcting body alignment.

### How it allows you to measure making a difference:

The CABA assessment can inform postural interventions, treatment planning and outcomes. It establishes a baseline of body position; this enables early changes in body alignment to be identified, preventative intervention to be implemented and outcomes of equipment provision to be measured through changes in body alignment.

It allows you to:

* Measure how effective the equipment you have selected is at supporting a child’s alignment.
* Compare between different set ups or pieces of equipment and justify which one is more effective at supporting a childs postural alignment.
* Measure changes in postural alignment and see that the equipment you have selected is meeting these changes.

The CABA provides a standardised approach that can outline a child’s individual alignment needs and ensure posture assessment and equipment provision are uniform across multi-professional services. This not only improves the outcome for the child in terms of maintaining alignment, improving function and comfort, it also improves the effectiveness of equipment provision.

As each child has individual presentations in regards to their body alignment, meaning there is no generic approach, instead, it requires all professions working with that child to have a universal view of their postural needs.

The CABA provides therapists with an accurate, standardised assessment of body alignment supporting a sound clinical rationale for equipment provision.

* This enables the child’s needs to be met from the outset, ensuring optimal positioning is implemented as early as possible.
* By being able to identify which area of alignment requires the most support therapists are able to adopt a tailored individualised approach to equipment selection and provision.

The CABA can accurately assess body alignment and evaluate effectiveness of equipment provision.

* This supports more targeted and appropriate equipment being prescribed, improving outcomes for the child and their families.
* It makes better use of available funding and develops a broader evidence base for posture management therapeutic interventions.

Equipment provision such as specialist seating, can include numerous versions available by several different suppliers. As a result, it can be difficult for therapists to articulate and quantify the difference between various makes and models of positional equipment.
* The CABA enables therapists to quantify the difference in a childs alignment, in a standardised format.
* This means the correct, most appropriate piece of equipment can be provided supporting the child posture as best as possible from the outset.
* Therapists can clearly justify clinical decisions to funding sources.

#### Evidence supporting The CABA: A Standardised assessment:

*[PhD Thesis: George, F.K. 2021. Identification and assessment of body alignment in children with cerebral palsy. Ph.D. thesis, University of Leeds.](https://etheses.whiterose.ac.uk/29768/)*

*[Article: Development and Content Validity of the Clinical Assessment of Body Alignment for Children With Cerebral Palsy, has been published in Pediatric Physical Therapy in Vol. 32, No. 2, April 2020.](https://journals.lww.com/pedpt/Fulltext/2020/04000/Development_and_Content_Validity_of_the_Clinical.14.aspx)*

*Podcast : The study, discussed by Frances K. George in Pediatric Physical Therapy podcast, investigated the first steps in psychometric testing of the Clinical Assessment of Body Alignment to measure body alignment in children with cerebral palsy. [Title: Volume 32, Issue 2 Produced by: Pediatric Physical Therapy](https://journals.lww.com/pedpt/pages/podcastepisodes.aspx?podcastid=1)*

*[You Tube video: Applying the study into practice.](https://www.youtube.com/watch?v=sGrXe9LDOaU)*

*Poster presentation: George, F.K., Benham, A. and Gabriel, L., 2021. Evaluation of the CABA’s ability to identify change in body alignment in posture management programmes in children with cerebral palsy. Poster presentation at: World Congress Physical Therapy, 2021*

*[Poster presentation: Clinical assessments designed to measure body alignment posture in children with cerebral palsy in CSP Innovation database](https://casestudies.csp.org.uk/innovation/clinical-assessments-designed-measure-body-alignment-posture-childrencerebral-palsy)*

*Article: George, F.K., Benham, A. and Gabriel, L., 2020. Clinical assessments designed to measure body alignment posture in children with cerebral palsy – a systematized review. Association of Pediatric Chartered Physiotherapists Journal, 11(1), pp.4-16.*
`;

export default text;
