import LabelGroups from "./_labels";

const head = {
  name: "Head",
  criteria: [
    {
      name: "Flexion / Extension",
      image: "/assessment-images/head-flexion-extension.png",
      options: [
        {
          label: "Extension",
          values: LabelGroups.FIVE_TWENTY,
        },
        {
          label: "Neutral",
          values: LabelGroups.NEUTRAL,
        },
        {
          label: "Flexion",
          values: LabelGroups.FIVE_TWENTY_SIXTY_PLUS,
        },
      ],
    },
    {
      name: "Side flexion",
      image: "/assessment-images/head-side-flexion.png",
      options: [
        {
          label: "Right",
          values: LabelGroups.FIVE_TWENTY_SIXTY_PLUS,
        },
        {
          label: "Neutral",
          values: LabelGroups.NEUTRAL,
        },
        {
          label: "Left",
          values: LabelGroups.FIVE_TWENTY_SIXTY_PLUS,
        },
      ],
    },
    {
      name: "Rotation",
      image: "/assessment-images/head-rotation.png",
      options: [
        {
          label: "Left",
          values: LabelGroups.FIVE_TWENTY_SIXTY_PLUS,
        },
        {
          label: "Neutral",
          values: LabelGroups.NEUTRAL,
        },
        {
          label: "Right",
          values: LabelGroups.FIVE_TWENTY_SIXTY_PLUS,
        },
      ],
    },
  ],
};

export default head;
