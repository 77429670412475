import React from "react";
import ReactMarkdown from "react-markdown";
import { Button, Modal, useModal } from "../../library";
import help from "./HelpText";
import copyright from "./CopyrightText";
import { EmailLink } from "../EmailLink";

const HelpModal: React.FC = () => {
  const { isModalOpen, closeModal, openModal } = useModal();

  return (
    <>
      <Button onClick={openModal} className="has-text-white is-ghost mx-4">
        <Button.Icon icon="question-circle" />
        <span>What is the CABA assessment tool?</span>
      </Button>

      <Modal isOpen={isModalOpen} portal onClose={closeModal}>
        <Modal.Header>What is the CABA assessment tool?</Modal.Header>
        <Modal.Body className="content m-0">
          <ReactMarkdown>{help}</ReactMarkdown>
          <ReactMarkdown>{copyright}</ReactMarkdown>

          <h3>Get in touch</h3>
          <EmailLink />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default HelpModal;
