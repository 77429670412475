import { AssessmentCriteria } from "./assessmentCriteria";

const assessmentDetails = [
  {
    value: "In existing equipment",
  },
  {
    value: "New provision",
  },
  {
    appliesTo: [AssessmentCriteria.SITTING],
    value: "Box sitting",
  },
  {
    appliesTo: [AssessmentCriteria.STANDING, AssessmentCriteria.LAYING],
    value: "Floor",
  },
];

export default assessmentDetails;
