import classNames from "classnames";
import React from "react";
import { EmailAddress } from "../../constants";
import { Button } from "../../library";
import { ClassNameProp } from "../../library/utils";

const EmailLinkBase: React.FC<ClassNameProp> = ({ className }) => (
  <a
    className={classNames("button mb-4", className)}
    href={`mailto:${EmailAddress}`}
  >
    <Button.Icon icon="envelope" />
    <span>{EmailAddress}</span>
  </a>
);

const EmailLinkDark: React.FC<ClassNameProp> = ({ className }) => (
  <EmailLinkBase className={classNames("has-text-white is-ghost", className)} />
);

const EmailLink: React.FC<ClassNameProp> = ({ className }) => (
  <EmailLinkBase className={className} />
);

export { EmailLinkDark, EmailLink };
