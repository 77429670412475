import React from "react";

import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { Patient } from "../PatientDetails";
import { AssessmentCriterion } from "../AssessmentCriteriaSelection";
import formatAssessmentCriterion from "../../utils/formatAssessmentCriterion";

const styles = StyleSheet.create({
  table: {
    maxHeight: 190,
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
  },
  tableHead: {
    width: 70,
    padding: 3,
  },
  tableData: {
    padding: 3,
    width: 270,
  },
  tableRowWide: {
    width: 340,
    padding: 3,
  },
  paddingBottom: {
    paddingBottom: 3,
  },
  textSmall: {
    fontSize: 7,
  },
});

type Props = {
  patientDetails: Patient;
  assessmentCriterion: AssessmentCriterion;
};

const PatientDetailsPdf: React.FC<Props> = ({
  patientDetails,
  assessmentCriterion,
}) => {
  return (
    <View style={styles.table}>
      <View style={styles.tableRow}>
        <Text style={styles.tableHead}>Patient name: </Text>
        <Text style={styles.tableData}>{patientDetails.name}</Text>
      </View>
      <View style={styles.tableRow}>
        <Text style={styles.tableHead}>Therapist: </Text>
        <Text style={styles.tableData}>{patientDetails.therapist}</Text>
      </View>
      <View style={styles.tableRow}>
        <Text style={styles.tableHead}>Location: </Text>
        <Text style={styles.tableData}>{patientDetails.location}</Text>
      </View>
      <View style={styles.tableRow}>
        <Text style={styles.tableHead}>Assessment: </Text>
        <Text style={styles.tableData}>
          {formatAssessmentCriterion(assessmentCriterion)}
        </Text>
      </View>
      <View style={styles.tableRow}>
        <Text style={styles.tableHead}>Date: </Text>
        <Text style={styles.tableData}>{patientDetails.date}</Text>
      </View>
      {patientDetails.notes ? (
        <View style={styles.tableRowWide}>
          <Text style={styles.paddingBottom}>Notes:</Text>
          <Text style={styles.textSmall}>{patientDetails.notes}</Text>
        </View>
      ) : null}
    </View>
  );
};

export default PatientDetailsPdf;
