import React, { PropsWithChildren, useCallback, useMemo } from "react";
import { StateStorageKeys } from "../../constants";
import useStickyState from "../../hooks/useStickyState";
import { Form } from "../../library";
import RemainingCharacters from "../RemainingCharacters";

export type Recommendation = {
  area: string;
  problem: string;
  recommendation: string;
};

const recommendationAreas = [
  "Head",
  "Trunk",
  "Pelvis",
  "Arms",
  "Legs",
  "Feet",
] as const;

const defaultRecommendation = {
  problem: "",
  recommendation: "",
};

export type Recommendations = Recommendation[];

export const useRecommendations = () => {
  const [tempRecommendations, realUpdateRecommendations] =
    useStickyState<Recommendations>([], StateStorageKeys.Recommendations);

  const findRecommendationData = useCallback(
    (area: string) =>
      tempRecommendations.find((data: Recommendation) => data.area === area),
    [tempRecommendations]
  );

  const recommendations = useMemo(
    () =>
      recommendationAreas.map((area) => ({
        ...defaultRecommendation,
        ...findRecommendationData(area),
        area,
      })),
    [findRecommendationData]
  );

  const updateRecommendations = useCallback(
    (recommendation: Recommendation) => {
      realUpdateRecommendations([
        ...recommendations.filter(({ area }) => area !== recommendation.area),
        recommendation,
      ]);
    },
    [recommendations, realUpdateRecommendations]
  );

  const clearRecommendations = useCallback(() => {
    realUpdateRecommendations([]);
  }, [realUpdateRecommendations]);

  return { recommendations, updateRecommendations, clearRecommendations };
};

type AreaInputProps = {
  onChange: (value: string) => void;
  value: string;
  max?: number;
};

const AreaInput: React.FC<PropsWithChildren<AreaInputProps>> = ({
  children,
  onChange,
  value,
  max = 500,
}) => (
  <Form.Field>
    <Form.Label>{children}</Form.Label>
    <Form.Textarea onChange={onChange} value={value} maxLength={max} />
    <Form.Help>
      <RemainingCharacters max={max} value={value} />
    </Form.Help>
  </Form.Field>
);

type AssessmentRecommendationProps = {
  updateRecommendation: (values: Partial<Recommendation>) => void;
  recommendation: Recommendation;
};

const AssessmentRecommendation: React.FC<AssessmentRecommendationProps> = ({
  recommendation,
  updateRecommendation,
}) => (
  <div className="columns">
    <div className="column">
      <AreaInput
        value={recommendation.problem}
        onChange={(problem: string) => updateRecommendation({ problem })}
      >
        Postural problems
      </AreaInput>
    </div>
    <div className="column">
      <AreaInput
        value={recommendation.recommendation}
        onChange={(recommendation: string) =>
          updateRecommendation({ recommendation })
        }
      >
        Recommendations / required interventions
      </AreaInput>
    </div>
  </div>
);

type Props = {
  updateRecommendations: (values: Recommendation) => void;
  recommendations: Recommendations;
};

const AssessmentRecommendations: React.FC<Props> = ({
  recommendations,
  updateRecommendations,
}) => (
  <>
    {recommendations.map((recommendation) => (
      <>
        <h2 className="is-size-5 has-text-weight-bold">
          {recommendation.area}
        </h2>
        <AssessmentRecommendation
          recommendation={recommendation}
          updateRecommendation={(updates: Partial<Recommendation>) =>
            updateRecommendations({
              ...recommendation,
              ...updates,
            })
          }
        />
      </>
    ))}
  </>
);

export default AssessmentRecommendations;
