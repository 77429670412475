import React from "react";

import { Button, useModal } from "../library";
import Assessment from "../components/Assessment";
import useStickyState from "../hooks/useStickyState";
import { StateStorageKeys } from "../constants";
import NewAssessmentModal from "../components/NewAssessmentModal";
import { AssessmentCriterion } from "../components/AssessmentCriteriaSelection";
import HelpModal from "../components/HelpModal";
import Logo from "../components/Logo";
import { EmailLinkDark } from "../components/EmailLink";

const AssessmentScreen: React.FC = () => {
  const [assessmentCriterion, setAssessmentCriterion] =
    useStickyState<AssessmentCriterion | null>(
      null,
      StateStorageKeys.AssessmentCriterion
    );

  const { isModalOpen, closeModal, openModal } = useModal();

  if (assessmentCriterion)
    return (
      <Assessment
        setAssessmentCriterion={setAssessmentCriterion}
        assessmentCriterion={assessmentCriterion}
      />
    );

  return (
    <div className="has-text-white has-background-feature is-fullheight is-flex is-flex-direction-column is-justify-content-space-between">
      <span></span>
      <div className="is-flex is-flex-direction-column is-align-items-center">
        <span className="is-size-3 mb-2">
          <Logo />
        </span>

        <h1 className="is-size-4 has-text-weight-semibold mb-3">
          CABA Assessment
        </h1>

        <p className="mb-1">&copy; Dr Frances George, 2021</p>

        <p className="is-italic mb-6">University of Leeds</p>

        <Button variant="primary" onClick={openModal}>
          Start assessment
        </Button>

        <NewAssessmentModal
          isOpen={isModalOpen}
          setAssessmentCriterion={setAssessmentCriterion}
          closeModal={closeModal}
        />
      </div>
      <div>
        <div>
          <HelpModal />
        </div>
        <div>
          <EmailLinkDark className="mx-4" />
        </div>
      </div>
    </div>
  );
};

export default AssessmentScreen;
