import React from "react";

import { Text, View, StyleSheet } from "@react-pdf/renderer";
import colors from "./colors";
import { Recommendations } from "../AssessmentRecommendations";

type Props = {
  equipmentPrescription: string;
  recommendations: Recommendations;
};

const resultsStyles = StyleSheet.create({
  table: {
    fontSize: 8,
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    borderTop: `0.5 solid ${colors.lightGrey}`,
    borderRight: `0.5 solid ${colors.lightGrey}`,
    minHeight: 85,
  },
  lastRow: {
    borderTop: `0.5 solid ${colors.lightGrey}`,
  },
  tableTitleRow: {
    display: "flex",
    flexDirection: "row",
    fontWeight: "extrabold",
    borderTop: `0.5 solid ${colors.lightGrey}`,
    borderRight: `0.5 solid ${colors.lightGrey}`,
    backgroundColor: colors.lighterGrey,
    fontSize: 10,
  },
  col1: {
    width: 110,
    padding: 5,
    borderLeft: `0.5 solid ${colors.lightGrey}`,
    backgroundColor: colors.lighterGrey,
    fontWeight: "extrabold",
  },
  col2: {
    padding: 5,
    width: 300,
    borderLeft: `0.5 solid ${colors.lightGrey}`,
  },
  col3: {
    width: 300,
    padding: 5,
    borderLeft: `0.5 solid ${colors.lightGrey}`,
  },
  col2and3: {
    width: 600,
    padding: 5,
    borderLeft: `0.5 solid ${colors.lightGrey}`,
    minHeight: 120,
  },
});

const TableRow: React.FC<{ col1: string; col2: string; col3: string }> = ({
  col1,
  col2,
  col3,
}) => {
  return (
    <View style={resultsStyles.tableRow}>
      <Text style={resultsStyles.col1}>{col1}</Text>
      <Text style={resultsStyles.col2}>{col2}</Text>
      <Text style={resultsStyles.col3}>{col3}</Text>
    </View>
  );
};

const AssessmentRecommendationsPdf: React.FC<Props> = ({
  recommendations,
  equipmentPrescription,
}) => {
  return (
    <View style={resultsStyles.table}>
      <View style={resultsStyles.tableTitleRow}>
        <Text style={resultsStyles.col1}>Body Segment</Text>
        <Text style={resultsStyles.col2}>Identified postural problems</Text>
        <Text style={resultsStyles.col3}>
          Recommendations / required interventions
        </Text>
      </View>
      {recommendations.map((recommendation) => (
        <TableRow
          key={recommendation.area}
          col1={recommendation.area}
          col2={recommendation.problem}
          col3={recommendation.recommendation}
        />
      ))}

      <View style={resultsStyles.tableRow}>
        <View style={resultsStyles.col1}>
          <Text>Equipment</Text>
          <Text>prescription:</Text>
        </View>
        <Text style={resultsStyles.col2and3}>{equipmentPrescription}</Text>
      </View>

      <View style={resultsStyles.lastRow}></View>
    </View>
  );
};

export default AssessmentRecommendationsPdf;
