import { useCallback, useRef, useState } from "react";

function useDelayedLoader(delay = 10, minDuration = 500) {
  const showTimer = useRef<NodeJS.Timeout>();
  const isShown = useRef(false);

  const [showLoader, setShowLoader] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const init = useCallback(() => {
    showTimer.current = setTimeout(() => {
      isShown.current = true;
      setShowLoader(true);
    }, delay);

    setIsLoaded(false);

    return () => clearTimeout(showTimer.current);
  }, [delay]);

  const onLoad = () => {
    setIsLoaded(true);
    clearTimeout(showTimer.current);
    setTimeout(() => {
      setShowLoader(false);
    }, minDuration);
  };

  return {
    init,
    onLoad,
    showLoader,
    isLoaded: isLoaded && !showLoader,
  };
}

export default useDelayedLoader;
