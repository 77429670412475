import React, { PropsWithChildren, useState } from "react";
import { ClassNameProp } from "../utils";
import MenuContext from "./MenuContext";

type Props = { defaultOpen?: boolean } & ClassNameProp;

const Nav: React.FC<PropsWithChildren<Props>> = ({
  children,
  defaultOpen = false,
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const ctx = {
    isOpen,
    toggle: (state?: boolean): void => setIsOpen((open) => state ?? !open),
  };

  return <MenuContext.Provider value={ctx}>{children}</MenuContext.Provider>;
};

export default Nav;
