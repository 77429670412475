import classNames from "classnames";
import React, { useContext } from "react";
import { assessmentCriteria } from "../../data";
import { Button } from "../../library";

import Context from "./Context";

const Options = () => {
  const { criterion, setCriterion } = useContext(Context);

  return criterion ? null : (
    <div className="is-flex is-justify-content-center is-flex-wrap-wrap">
      {assessmentCriteria.map((c) => (
        <Button
          className={classNames("m-3 p-6 is-flex-grow-1 is-fullwidth-mobile")}
          key={c.id}
          onClick={() => setCriterion(c.id)}
        >
          {c.label}
        </Button>
      ))}
    </div>
  );
};

export default Options;
