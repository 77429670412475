import { PDFDownloadLink } from "@react-pdf/renderer";
import classNames from "classnames";
import React from "react";
import { Button, Loader } from "../../library";
import { ClassNameProp } from "../../library/utils";
import AssessmentSummaryPdf from "../AssessmentSummaryPdf";
import { AllAssessmentData } from "../types";

type ExportPdfProps = {
  filename?: string;
  onExport?: () => void;
} & AllAssessmentData &
  ClassNameProp;

const sanitizeFileName = (s: string) =>
  s.replace(/[^a-z0-9]/gi, "_").toLowerCase();

const formatFileName = (
  name: string,
  assessmentDate: string,
  criterion: string
) =>
  sanitizeFileName(
    `caba_${name || "anonymous"}_${criterion}_${assessmentDate}.pdf`
  );

const ExportPdf: React.FC<ExportPdfProps> = ({
  onExport,
  assessmentCriterion,
  assessmentResults,
  patientDetails,
  className,
  assessmentImages,
  recommendations,
  equipmentPrescription,
  filename = formatFileName(
    patientDetails.name,
    patientDetails.date,
    assessmentCriterion.criterion
  ),
}) => {
  return (
    <PDFDownloadLink
      onClick={onExport}
      fileName={filename}
      document={
        <AssessmentSummaryPdf
          equipmentPrescription={equipmentPrescription}
          recommendations={recommendations}
          assessmentImages={assessmentImages}
          assessmentCriterion={assessmentCriterion}
          patientDetails={patientDetails}
          assessmentResults={assessmentResults}
        />
      }
      className={classNames("button m-3", className)}
    >
      {({ loading }) =>
        loading ? (
          <Loader size="small" />
        ) : (
          <>
            <span>Export assessment to PDF</span>
            <Button.Icon icon="file-export" />
          </>
        )
      }
    </PDFDownloadLink>
  );
};

export default ExportPdf;
