import React, { useCallback } from "react";
import { StateStorageKeys } from "../../constants";
import useStickyState from "../../hooks/useStickyState";
import { Form } from "../../library";
import RemainingCharacters from "../RemainingCharacters";

export type Patient = {
  name: string;
  therapist: string;
  location: string;
  date: string;
  notes: string;
};

const defaultPatient = {
  name: "",
  therapist: "",
  location: "",
  date: new Date().toLocaleDateString("en-CA"),
  notes: "",
};

export const usePatient = () => {
  const [patient, realUpdatePatient] = useStickyState<Patient>(
    defaultPatient,
    StateStorageKeys.Patient
  );

  const updatePatient = useCallback(
    (updates: Partial<Patient>) => {
      realUpdatePatient({
        ...patient,
        ...updates,
      });
    },
    [patient, realUpdatePatient]
  );

  const clearPatient = useCallback(() => {
    realUpdatePatient({
      ...defaultPatient,
      therapist: patient.therapist, // Safe to assume the therapist is usually the same each time?
    });
  }, [patient, realUpdatePatient]);

  return { patient, updatePatient, clearPatient };
};

type Props = {
  updatePatient: (values: Partial<Patient>) => void;
  patient: Patient;
};

const PatientDetails: React.FC<Props> = ({ updatePatient, patient }) => {
  return (
    <Form className="p-3">
      <Form.Field>
        <Form.Label>Patient name</Form.Label>
        <Form.Input
          onChange={(name: string): void => updatePatient({ name })}
          value={patient.name}
          maxLength={150}
        />
      </Form.Field>

      <Form.Field>
        <Form.Label>Therapist name</Form.Label>
        <Form.Input
          onChange={(therapist: string): void => updatePatient({ therapist })}
          value={patient.therapist}
          maxLength={150}
        />
      </Form.Field>

      <Form.Field>
        <Form.Label>Location</Form.Label>
        <Form.Input
          onChange={(location: string): void => updatePatient({ location })}
          value={patient.location}
          maxLength={150}
        />
      </Form.Field>

      <Form.Field>
        <Form.Label>Date</Form.Label>
        <Form.Input
          type="date"
          onChange={(date: string): void => updatePatient({ date })}
          value={patient.date}
          min="2022-01-01"
        />
      </Form.Field>

      <Form.Field>
        <Form.Label>Notes</Form.Label>
        <Form.Textarea
          onChange={(notes: string): void => updatePatient({ notes })}
          value={patient.notes}
          maxLength={700}
        />
        <Form.Help>
          <RemainingCharacters max={700} value={patient.notes} />
        </Form.Help>
      </Form.Field>
    </Form>
  );
};

export default PatientDetails;
