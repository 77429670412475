const LabelGroups = {
  NEUTRAL: [{ label: "0° - 5°", value: 0 }],
  NEUTRAL_90: [{ label: "85° - 95°", value: 0 }],
  FIVE_TWENTY: [{ label: "5° - 20°", value: 1 }],
  FIVE_TWENTY_PLUS: [
    { label: "5° - 20°", value: 1 },
    { label: "20°+", value: 2 },
  ],
  NINTY_ONETWENTY_PLUS: [
    { label: "90° - 120°", value: 1 },
    { label: "120°+", value: 2 },
  ],
  NINTY_SIXTY_TWENTY_FIVE: [
    { label: "60° - 90°", value: 1 },
    { label: "20° - 60°", value: 2 },
    { label: "5° - 20°", value: 3 },
  ],
  FIVE_FIFTEEN_PLUS: [
    { label: "5° - 15°", value: 1 },
    { label: "15°+", value: 2 },
  ],
  FIVE_TWENTY_FORTY_PLUS: [
    { label: "5° - 20°", value: 1 },
    { label: "20° - 40°", value: 2 },
    { label: "40°+", value: 3 },
  ],
  FIVE_TWENTY_SIXTY_PLUS: [
    { label: "5° - 20°", value: 1 },
    { label: "20° - 60°", value: 2 },
    { label: "60°+", value: 3 },
  ],
  FIVE_FORTY_NINTY_PLUS: [
    { label: "5° - 40°", value: 1 },
    { label: "40° - 90°", value: 2 },
    { label: "90°+", value: 3 },
  ],
};

export default LabelGroups;
