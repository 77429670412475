import React, { PropsWithChildren, useContext } from "react";
import { Button } from "../../library";
import Context from "./Context";
import { AssessmentCriterion } from "./types";

type Props = {
  onClick: (value: AssessmentCriterion) => void;
};

const Details: React.FC<PropsWithChildren<Props>> = ({
  onClick,
  children,
  ...props
}) => {
  const { criterion, details, setCriterion } = useContext(Context);

  return (
    <>
      {criterion && <Button onClick={() => setCriterion(null)}>Back</Button>}
      <Button
        variant="primary"
        disabled={!criterion}
        onClick={() =>
          criterion
            ? onClick({
                criterion,
                details,
              })
            : undefined
        }
        {...props}
      >
        {children}
      </Button>
    </>
  );
};

export default Details;
