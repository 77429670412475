import Provider from "./Provider";
import Options from "./Options";
import Details from "./Details";
import Button from "./Button";

export default Object.assign(Provider, {
  Options,
  Details,
  Button,
});

export * from "./types";
