export enum AssessmentCriteria {
  SITTING = "Sitting",
  STANDING = "Standing",
  LAYING = "Lying",
}

const criteria = [
  { id: AssessmentCriteria.SITTING, label: "Sitting" },
  { id: AssessmentCriteria.STANDING, label: "Standing" },
  { id: AssessmentCriteria.LAYING, label: "Lying" },
];

export default criteria;
