import { useCallback } from "react";
import { StateStorageKeys } from "../../constants";
import useStickyState from "../../hooks/useStickyState";
import { SketchData } from "../Sketch";

type SavedSketchData = {
  data: string;
  image: string;
};

type SavedSketches = {
  [id: string]: SavedSketchData;
};

const useSketch = () => {
  const [savedSketches, setSavedSketches] = useStickyState<SavedSketches>(
    {},
    StateStorageKeys.Sketch
  );

  const setSketchData = useCallback(
    (id: string, data: SketchData) => {
      setSavedSketches({
        ...savedSketches,
        [id]: data,
      });
    },
    [setSavedSketches, savedSketches]
  );

  const clearSketchData = useCallback(() => {
    setSavedSketches({});
  }, [setSavedSketches]);

  return {
    savedSketches,
    setSketchData,
    clearSketchData,
  };
};

export default useSketch;
