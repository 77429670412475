import classNames from "classnames";
import React, { SyntheticEvent, useCallback } from "react";

import { ClassNameProp, Variant } from "../utils";

type TextareaProps = {
  onChange: (value: string) => void;
  value: string | number;
  placeholder?: string;
  variant?: Variant;
  maxLength?: number;
} & ClassNameProp;

const Textarea: React.FC<TextareaProps> = ({
  onChange,
  value,
  placeholder,
  className,
  variant,
  ...props
}) => {
  const doOnChange = useCallback(
    (e: SyntheticEvent<HTMLTextAreaElement>) => onChange(e.currentTarget.value),
    [onChange]
  );
  return (
    <textarea
      onChange={doOnChange}
      value={value}
      className={classNames("textarea", className, {
        [`is-${variant}`]: variant,
      })}
      placeholder={placeholder}
      {...props}
    />
  );
};

export default Textarea;
