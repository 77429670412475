import classNames from "classnames";
import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useRef,
} from "react";
import useClickOutside from "../hooks/useClickOutside";
import { ClassNameProp } from "../utils";
import MenuContext from "./MenuContext";

const MenuToggle: React.FC<PropsWithChildren<ClassNameProp>> = ({
  className,
}) => {
  const { isOpen, toggle } = useContext(MenuContext);
  const ref = useRef<HTMLButtonElement | null>(null);

  useClickOutside(
    useCallback(() => toggle(false), [toggle]),
    ref
  );

  return (
    <button
      ref={ref}
      className={classNames("ml-0 navbar-burger", className, {
        "is-active": isOpen,
      })}
      aria-label="menu"
      aria-expanded={isOpen ? "true" : "false"}
      onClick={() => toggle()}
    >
      <span aria-hidden="true" />
      <span aria-hidden="true" />
      <span aria-hidden="true" />
    </button>
  );
};

export default MenuToggle;
