import classNames from "classnames";
import React, { PropsWithChildren } from "react";
import Icon from "../icon";
import { ClassNameProp, Variant } from "../utils";

export type ButtonProps = ClassNameProp & {
  onClick: () => void;
  disabled?: boolean;
  title?: string;
  variant?: Variant;
};

const Button: React.FC<PropsWithChildren<ButtonProps>> = ({
  onClick,
  variant,
  disabled = false,
  children,
  className,
  title,
  ...props
}) => (
  <button
    title={title}
    className={classNames("button", { [`is-${variant}`]: variant }, className)}
    disabled={disabled}
    onClick={(e) => {
      e.stopPropagation();
      onClick();
    }}
    {...props}
  >
    {children}
  </button>
);

type ButtonIconProps = { icon: string };

const ButtonIcon: React.FC<ButtonIconProps> = (props) => (
  <span className="icon">
    <Icon {...props} />
  </span>
);

type GroupProps = {
  hasAddons?: boolean;
  controlClassName?: string;
} & ClassNameProp;

const Group: React.FC<PropsWithChildren<GroupProps>> = ({
  className,
  controlClassName,
  children,
  hasAddons = false,
}) => {
  const buttons = React.Children.toArray(children).map((button, idx) => (
    <span key={idx} className={classNames("control", controlClassName)}>
      {button}
    </span>
  ));

  return (
    <div
      className={classNames(
        "field ",
        {
          "has-addons": hasAddons,
          "is-grouped": !hasAddons,
        },
        className
      )}
    >
      {buttons}
    </div>
  );
};

export default Object.assign(Button, { Group, Icon: ButtonIcon });
