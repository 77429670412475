import { useState, useCallback } from "react";

const isSameType = (one: unknown, two: unknown) =>
  typeof one === typeof two && Array.isArray(one) === Array.isArray(two);

function useStickyState<T>(defaultValue: T, key: string): [T, (v: T) => void] {
  const [value, setValue] = useState<T>(() => {
    const stickyValue = window.localStorage.getItem(key);
    const result =
      stickyValue !== null ? JSON.parse(stickyValue) : defaultValue;

    return isSameType(result, defaultValue) ? result : defaultValue;
  });

  const setStickyValue = useCallback(
    (newValue: T) => {
      setValue(newValue);
      window.localStorage.setItem(key, JSON.stringify(newValue));
    },
    [key, setValue]
  );

  return [value, setStickyValue];
}

export default useStickyState;
