import { useCallback, useState } from "react";

const useWizard = (pages: number) => {
  const [page, setPage] = useState(1);

  const nextPage = useCallback(() => setPage((p) => p + 1), [setPage]);
  const previousPage = useCallback(() => setPage((p) => p - 1), [setPage]);
  const resetPage = useCallback(() => setPage(1), [setPage]);

  return {
    page,
    resetPage,
    nextPage: page < pages ? nextPage : undefined,
    previousPage: page > 1 ? previousPage : undefined,
  };
};

export default useWizard;
