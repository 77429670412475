import classNames from "classnames";
import React, { PropsWithChildren } from "react";
import { ClassNameProp } from "../utils";

const End: React.FC<PropsWithChildren<ClassNameProp>> = ({
  className,
  children,
}) => {
  return <div className={classNames("navbar-end", className)}>{children}</div>;
};

export default End;
