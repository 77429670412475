import classNames from "classnames";
import React, { CSSProperties, PropsWithChildren } from "react";
import { ClassNameProp, Variant } from "../utils";

type Props = {
  size?: "small" | "medium" | "large";
  variant?: Variant;
  style?: CSSProperties;
} & ClassNameProp;

const Tag: React.FC<PropsWithChildren<Props>> = ({
  size,
  children,
  variant,
  className,
  style,
}) => (
  <span
    style={style}
    className={classNames(
      "tag",
      {
        [`is-${size}`]: size,
        [`is-${variant}`]: variant,
      },
      className
    )}
  >
    {children}
  </span>
);

export default Tag;
