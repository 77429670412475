import React from "react";
import { Button } from "../../library";

type NextProp = {
  onNext?: () => void;
};

type PrevProp = {
  onPrev?: () => void;
};

const Prev: React.FC<PrevProp> = ({ onPrev }) =>
  onPrev ? (
    <Button onClick={onPrev}>
      <Button.Icon icon="arrow-left" /> <span>Back</span>
    </Button>
  ) : (
    <span></span>
  );

const Next: React.FC<NextProp> = ({ onNext }) =>
  onNext ? (
    <Button onClick={onNext}>
      <span>Next</span> <Button.Icon icon="arrow-right" />
    </Button>
  ) : (
    <span></span>
  );

export default Object.assign(
  {},
  {
    Next,
    Prev,
  }
);
