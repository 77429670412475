import { Colors } from "../../constants";

const colors = {
  primary: "#3d76a5",
  blue: "#3d76a5",
  white: "white",
  green: Colors.green,
  yellow: Colors.yellow,
  orange: Colors.orange,
  red: Colors.red,
  black: "#000000",
  lightGrey: "#b9b9b9",
  lighterGrey: "#e5e5e5",
} as const;

export default colors;
