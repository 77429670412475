import { useCallback, useMemo } from "react";
import { StateStorageKeys } from "../../constants";
import { Assessment, AssessmentSection } from "../../data/assessment";
import useStickyState from "../../hooks/useStickyState";

type AssessmentDataValue = {
  direction: string;
  value: number;
};

type AssessmentItem = {
  main: string;
  sub: string;
};

type AssessmentData = AssessmentItem & {
  data: AssessmentDataValue;
};

type AssessmentCriteriaResult = AssessmentSection & {
  data?: AssessmentDataValue;
};

export type AssessmentResult = {
  name: string;
  score: number;
  criteria: AssessmentCriteriaResult[];
};

const useAssessment = (assessments: Assessment[]) => {
  const [assessmentData, setAssessmentData] = useStickyState<AssessmentData[]>(
    [],
    StateStorageKeys.Assessments
  );

  const doSetAssessmentData = useCallback(
    (assessment: AssessmentItem, direction: string, value: number) => {
      setAssessmentData([
        ...assessmentData.filter(
          ({ main, sub }: AssessmentData) =>
            main !== assessment.main || sub !== assessment.sub
        ),
        {
          main: assessment.main,
          sub: assessment.sub,
          data: { direction, value },
        },
      ]);
    },
    [assessmentData, setAssessmentData]
  );

  const findAssessmentData = useCallback(
    (main: string, sub: string) =>
      assessmentData.find(
        (data: AssessmentData) => data.main === main && data.sub === sub
      )?.data,
    [assessmentData]
  );

  const clearAssessmentData = useCallback(() => {
    setAssessmentData([]);
  }, [setAssessmentData]);

  const assessmentResults: AssessmentResult[] = useMemo(
    () =>
      assessments.map((main) => {
        const criteria = main.criteria.map((sub) => ({
          data: findAssessmentData(main.name, sub.name),
          ...sub,
        }));

        const score = criteria
          .map((c) => c.data?.value || 0)
          .reduce((a, b) => a + b, 0);

        return {
          name: main.name,
          score,
          criteria,
        };
      }),
    [assessments, findAssessmentData]
  );

  return {
    assessmentResults,
    clearAssessmentData,
    setAssessmentData: doSetAssessmentData,
    findAssessmentData,
  };
};

export default useAssessment;
