const text = `
### Copyright

[CABA assessment descriptors and illustrations copyright © George, F.K (2021) The identification and
assessment of body alignment in children with cerebral palsy. PhD Thesis. University of Leeds.
](https://etheses.whiterose.ac.uk/29768/)


George, F.K et al (2020). Development and Content Validity of the Clinical Assessment of Body
Alignment for Children with Cerebral Palsy. Pediatric Physical Therapy, 32(2), pp.137-143.

If wising to license the CABA for commercial clinical trials, industry sponsored research or 
integration into electronic health record systems, please contact us by email.
`;

export default text;
