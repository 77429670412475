import React, { PropsWithChildren, useState } from "react";
import { AssessmentCriteria } from "../../data";

import Context from "./Context";

const Provider: React.FC<PropsWithChildren> = ({ children }) => {
  const [criterion, setCriterion] = useState<AssessmentCriteria | null>(null);

  const [details, setDetails] = useState<string>("");

  const ctx = {
    criterion,
    setCriterion,
    details,
    setDetails,
  };

  return <Context.Provider value={ctx}>{children}</Context.Provider>;
};

export default Provider;
