import { createContext } from "react";
import { AssessmentCriteria } from "../../data";

type Props = {
  criterion: AssessmentCriteria | null;
  setCriterion: (criterion: AssessmentCriteria | null) => void;
  details: string;
  setDetails: (details: string) => void;
};

const Context = createContext<Props>({
  criterion: null,
  setCriterion: () => {
    //
  },
  details: "",
  setDetails: () => {
    //
  },
});

export default Context;
