export const StateStorageKeys = {
  AssessmentCriterion: "assessment-criterion",
  Assessments: "assessments",
  Patient: "patient",
  Sketch: "sketch-v2",
  Recommendations: "recommendations-v2",
  Equipment: "equipment",
} as const;

export const Colors = {
  green: "#168a1e",
  yellow: "#f2e555",
  orange: "#ef790b",
  red: "#c92f2f",
} as const;

export const EmailAddress = "caba.assessment@gmail.com";
