import classNames from "classnames";
import React, { PropsWithChildren } from "react";
import { ClassNameProp } from "../utils";

const Menu: React.FC<PropsWithChildren<ClassNameProp>> = ({
  className,
  children,
}) => (
  <article className={classNames("menu is-fullwidth", className)}>
    {children}
  </article>
);

const Label: React.FC<PropsWithChildren<ClassNameProp>> = ({
  className,
  children,
}) => <p className={classNames("menu-label", className)}>{children}</p>;

type ListProps = {
  expanded?: boolean;
} & ClassNameProp;

const List: React.FC<PropsWithChildren<ListProps>> = ({
  className,
  children,
  expanded = true,
}) => (
  <ul
    className={classNames("menu-list", className, {
      "is-hidden": !expanded,
    })}
  >
    {children}
  </ul>
);

const Item: React.FC<PropsWithChildren<ClassNameProp>> = ({
  className,
  children,
}) => <li className={classNames(className)}>{children}</li>;

export default Object.assign(Menu, { Label, List, Item });
