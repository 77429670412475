import React from "react";

import Icon, { IconProps } from "../icon";

export type FormIconProps = IconProps & {
  align: "left" | "right";
};

const Input: React.FC<FormIconProps> = ({ align, ...iconProps }) => {
  return (
    <span className={`icon is-small is-${align}`}>
      <Icon {...iconProps} />
    </span>
  );
};

export default Input;
