import classNames from "classnames";
import React, { PropsWithChildren, SyntheticEvent } from "react";
import { ClassNameProp } from "../utils";

export type FormProps = {
  onSubmit?: (e: SyntheticEvent<HTMLFormElement>) => void;
} & ClassNameProp;

const Form: React.FC<PropsWithChildren<FormProps>> = ({
  children,
  className,
  onSubmit = (e) => {
    e.preventDefault();
  },
}) => {
  return (
    <form
      onSubmit={onSubmit}
      className={classNames("form is-fullwidth", className)}
    >
      {children}
    </form>
  );
};

export default Form;
