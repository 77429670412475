import classNames from "classnames";
import React, { PropsWithChildren } from "react";
import { ClassNameProp } from "../utils";

export type FormControlProps = ClassNameProp;

type IconChild = { props: { align: string } };

const getAlignProp = (child: React.ReactNode): string =>
  (child as IconChild)?.props?.align;

const Control: React.FC<PropsWithChildren<FormControlProps>> = ({
  className,
  children,
}) => {
  const iconAlignments = new Set(
    React.Children.toArray(children).map(getAlignProp)
  );

  return (
    <div
      className={classNames("control", className, {
        "has-icons-left": iconAlignments.has("left"),
        "has-icons-right": iconAlignments.has("right"),
      })}
    >
      {children}
    </div>
  );
};

export default Control;
