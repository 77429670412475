import { useState, useCallback } from "react";

type useModalReturnProps = {
  isModalOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
};

const useModal = (open = false): useModalReturnProps => {
  const [isModalOpen, setIsModalOpen] = useState(open);

  const openModal = useCallback(() => setIsModalOpen(true), [setIsModalOpen]);
  const closeModal = useCallback(() => setIsModalOpen(false), [setIsModalOpen]);

  return { isModalOpen, openModal, closeModal };
};

export default useModal;
