import { useCallback, useState } from "react";

function useNextPrev<T>(items: T[]) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const current = items[currentIndex];

  const next =
    currentIndex === items.length - 1
      ? undefined
      : () => setCurrentIndex(currentIndex + 1);

  const prev =
    currentIndex === 0 ? undefined : () => setCurrentIndex(currentIndex - 1);

  const setCurrent = useCallback(
    (item: T) => {
      const idx = items.findIndex((i) => i === item);
      setCurrentIndex(idx);
    },
    [items, setCurrentIndex]
  );

  const reset = useCallback(() => setCurrentIndex(0), [setCurrentIndex]);

  return {
    setCurrent,
    currentIndex,
    current,
    next,
    prev,
    reset,
    isLast: currentIndex === items.length - 1,
  };
}

export default useNextPrev;
