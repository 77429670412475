import React, { PropsWithChildren, useState } from "react";
import classNames from "classnames";
import { Icon, Menu } from "../../library";
import { ClassNameProp } from "../../library/utils";
import styles from "./AssessmentMenu.module.scss";

type AssessmentLinkProps = {
  active?: boolean;
  label?: string;
  expanded?: boolean;
  onClick: (e: React.MouseEvent) => void;
} & ClassNameProp;

const MenuItemLink: React.FC<PropsWithChildren<AssessmentLinkProps>> = ({
  className,
  label,
  expanded,
  children,
  active,
  onClick,
}) => (
  <button
    aria-expanded={expanded}
    aria-label={label}
    onClick={onClick}
    className={classNames(styles.menuButton, className, {
      [styles.menuButtonActive]: active,
    })}
    aria-current={active ? "page" : false}
  >
    {children}
  </button>
);

type AssessmentMenuItemProps = {
  id: string;
  label: string;
};

export type AssessmentMenuItem = AssessmentMenuItemProps & {
  icon?: string;
  children?: AssessmentMenuItemProps[];
};

type AssesmentMenuProps = {
  items: AssessmentMenuItem[];
  currentItem: string[];
  onSelect: (...path: string[]) => void;
};

const AssesmentMenu: React.FC<AssesmentMenuProps> = ({
  items,
  currentItem: [currentMain, currentChild],
  onSelect,
}) => {
  const [open, setOpen] = useState<string>();

  const menu = items.map((main) => {
    const mainActive = currentMain === main.id;

    const isExpanded = mainActive || open === main.id;

    const submenu =
      main.children?.map((child) => {
        const subActive = mainActive && currentChild === child.id;

        return (
          <Menu.Item key={`${main.id}:${child.id}`}>
            <MenuItemLink
              label={`${main.label}: ${child.label}`}
              active={subActive}
              onClick={() => onSelect(main.id, child.id)}
            >
              {child.label}
            </MenuItemLink>
          </Menu.Item>
        );
      }) ?? [];

    const hasSubmenu = submenu.length > 0;

    const menuIcon = main.icon
      ? main.icon
      : hasSubmenu
      ? isExpanded
        ? "chevron-down"
        : "chevron-right"
      : "minus";

    return (
      <Menu.Item key={main.id}>
        <MenuItemLink
          expanded={isExpanded && hasSubmenu}
          active={isExpanded && !hasSubmenu}
          onClick={(e) => {
            if (main.children) {
              e.stopPropagation();
              setOpen(isExpanded ? undefined : main.id);
            } else {
              onSelect(main.id);
            }
          }}
        >
          <Icon icon={menuIcon} />
          {main.label}
        </MenuItemLink>
        <Menu.List className="ml-5 pl-2" expanded={isExpanded && hasSubmenu}>
          {submenu}
        </Menu.List>
      </Menu.Item>
    );
  });

  return (
    <Menu>
      <Menu.List>{menu}</Menu.List>
    </Menu>
  );
};

export default AssesmentMenu;
