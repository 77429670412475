import React, { PropsWithChildren } from "react";

import SwitchContext from "./SwitchContext";

type Props = { caseId?: string | number };

const Switch: React.FC<PropsWithChildren<Props>> = ({ caseId, children }) => {
  const ctx = {
    caseId,
  };

  return (
    <SwitchContext.Provider value={ctx}>{children}</SwitchContext.Provider>
  );
};

export default Switch;
