import React, { useCallback, useEffect, useRef, useState } from "react";
import CanvasDraw from "react-canvas-draw";
import toImageDataUrl from "./toImageDataUrl";
import Controls from "./Controls";
import { defaultBrushColor, defaultBrushSize } from "./constants";

type Dimensions = {
  width: number;
  height: number;
};

export type SketchData = {
  image: string;
  data: string;
};

type Props = {
  onChange: (d: SketchData) => void;
  image: string;
  saveData?: string;
};

const getSizeAdjustedToAspectRatio = (
  sourceWidth: number,
  sourceHeight: number,
  dWidth: number,
  dHeight: number
) => {
  const ratio = Math.min(dWidth / sourceWidth, dHeight / sourceHeight);

  return {
    height: sourceHeight * ratio,
    width: sourceWidth * ratio,
  };
};

const Sketch: React.FC<Props> = ({ onChange, image, saveData }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const ref = useRef<CanvasDraw>(null);
  const [brushColor, setBrushColor] = useState<string>(defaultBrushColor.value);
  const [brushRadius, setBrushRadius] = useState<number>(
    defaultBrushSize.value
  );

  const [imageDimensions, setImageDimensions] = useState<Dimensions>();

  const handleOnChange = useCallback(
    ({ canvas, getSaveData }: CanvasDraw) =>
      onChange({
        image: toImageDataUrl(canvas.drawing, canvas.grid),
        data: getSaveData(),
      }),
    [onChange]
  );

  useEffect(() => {
    const getImageDimensions = () => {
      const i = new Image();

      i.onload = () => {
        const ref = containerRef.current;

        if (!ref) return;

        setImageDimensions({
          width: i.width,
          height: i.height,
        });
      };

      i.src = image;
    };

    getImageDimensions();

    // TODO - resize Sketch
    // window.addEventListener("resize", getImageDimensions);
    // return () => {
    //   window.removeEventListener("resize", getImageDimensions);
    // };
  }, [image]);

  const dimensions =
    imageDimensions &&
    containerRef.current?.clientWidth &&
    containerRef.current?.clientHeight
      ? getSizeAdjustedToAspectRatio(
          imageDimensions.width,
          imageDimensions.height,
          containerRef.current.clientWidth,
          containerRef.current.clientHeight
        )
      : undefined;

  return (
    <div className="is-flex is-fullheight">
      <div
        ref={containerRef}
        className="is-flex is-align-items-center is-flex-grow-1"
      >
        {dimensions && (
          <CanvasDraw
            key={JSON.stringify(dimensions)}
            immediateLoading
            saveData={saveData}
            canvasWidth={dimensions.width}
            canvasHeight={dimensions.height}
            ref={ref}
            imgSrc={image}
            brushColor={brushColor}
            catenaryColor={brushColor}
            brushRadius={brushRadius}
            lazyRadius={0}
            hideGrid
            onChange={handleOnChange}
            className="mx-auto has-border is-relative"
          />
        )}
      </div>
      <Controls
        onClear={() => ref.current?.eraseAll()}
        onUndo={() => ref.current?.undo()}
        brushColor={brushColor}
        brushRadius={brushRadius}
        onChangeBrushColor={setBrushColor}
        onChangeBrushRadius={setBrushRadius}
      />
    </div>
  );
};

export default Sketch;
