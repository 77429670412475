import React, { PropsWithChildren } from "react";
import { ClassNameProp } from "../utils";

const Header: React.FC<PropsWithChildren<ClassNameProp>> = ({
  children,
  className,
}) => {
  return <div className={className}>{children}</div>;
};

export default Header;
