import { RefObject, useCallback, useEffect } from "react";

/**
 * Hook that calls callback when user clicks outside of the passed ref
 */
const useClickOutside = <T extends HTMLElement | undefined>(
  callback: VoidFunction,
  ref?: RefObject<T>,
  delay = 100
): void => {
  const handleMouseUp = useCallback(
    (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      if (ref === undefined || !ref.current?.contains(target)) {
        delay > 0 ? setTimeout(callback, delay) : callback();
      }
    },
    [callback, ref, delay]
  );

  useEffect(() => {
    document.addEventListener("click", handleMouseUp);
    return (): void => {
      document.removeEventListener("click", handleMouseUp);
    };
  }, [handleMouseUp]);
};

export default useClickOutside;
