import classNames from "classnames";
import React, { useState } from "react";
import { Button, Switch, Modal, useModal } from "../../library";
import AssessmentCriteriaSelection, {
  AssessmentCriterion,
} from "../AssessmentCriteriaSelection";
import ExportPdf from "../ExportPdf";
import { AllAssessmentData } from "../types";
import useWizard from "./useWizard";

type CompleteAssessmentProps = {
  completeAnotherAssessment: (assessment: AssessmentCriterion) => void;
  exitAssessment: () => void;
} & AllAssessmentData;

const CompleteAssessment: React.FC<CompleteAssessmentProps> = ({
  assessmentCriterion,
  assessmentResults,
  patientDetails,
  completeAnotherAssessment,
  exitAssessment,
  assessmentImages,
  recommendations,
  equipmentPrescription,
}) => {
  const { isModalOpen, closeModal, openModal } = useModal();

  const { page, nextPage, previousPage, resetPage } = useWizard(3);

  const [hasExported, setHasExported] = useState(false);

  const exitModal = () => {
    resetPage();
    closeModal();
    setHasExported(false);
  };

  const doExitAssessment = () => {
    exitAssessment();
    exitModal();
  };

  const doCompleteAnotherAssessment = (assessment: AssessmentCriterion) => {
    completeAnotherAssessment(assessment);
    exitModal();
  };

  return (
    <>
      <Button variant="primary" onClick={openModal}>
        Complete assessment
      </Button>

      <Switch caseId={page}>
        <AssessmentCriteriaSelection>
          <Modal isOpen={isModalOpen} portal onClose={exitModal}>
            <Modal.Header>
              <Switch.Case caseId={1}>Export assessment</Switch.Case>
              <Switch.Case caseId={2}>Reassess this patient?</Switch.Case>
              <Switch.Case caseId={3}>Choose assessment criteria</Switch.Case>
            </Modal.Header>
            <Modal.Body>
              <Switch.Case caseId={1}>
                <div className="is-flex is-align-items-center is-flex-direction-column">
                  <ExportPdf
                    className={classNames({
                      "is-hidden": hasExported,
                    })}
                    equipmentPrescription={equipmentPrescription}
                    recommendations={recommendations}
                    assessmentCriterion={assessmentCriterion}
                    onExport={() => setHasExported(true)}
                    patientDetails={patientDetails}
                    assessmentResults={assessmentResults}
                    assessmentImages={assessmentImages}
                  />
                  {hasExported && <span>Your PDF is downloading...</span>}
                </div>
              </Switch.Case>
              <Switch.Case caseId={2}>
                <div className="is-flex is-flex-direction-column">
                  {nextPage && (
                    <Button
                      className="m-3"
                      variant="primary"
                      onClick={nextPage}
                    >
                      Complete another assessment
                    </Button>
                  )}
                  <Button
                    className="m-3"
                    variant="danger"
                    onClick={doExitAssessment}
                  >
                    No thanks, I'm done
                  </Button>
                </div>
              </Switch.Case>
              <Switch.Case caseId={3}>
                <AssessmentCriteriaSelection.Options />
                <AssessmentCriteriaSelection.Details />
              </Switch.Case>
            </Modal.Body>
            <Modal.Footer>
              <Switch.Case caseId={1}>
                {nextPage && (
                  <Button onClick={nextPage}>
                    {hasExported ? "Next" : "Skip"}
                  </Button>
                )}
              </Switch.Case>
              <Switch.Case caseId={2}>
                {previousPage && <Button onClick={previousPage}>Back</Button>}
              </Switch.Case>
              <Switch.Case caseId={3}>
                <AssessmentCriteriaSelection.Button
                  onClick={doCompleteAnotherAssessment}
                >
                  Start next assessment
                </AssessmentCriteriaSelection.Button>
              </Switch.Case>
            </Modal.Footer>
          </Modal>
        </AssessmentCriteriaSelection>
      </Switch>
    </>
  );
};

export default CompleteAssessment;
