import React from "react";

type RadioOption<T> = {
  value: T;
  label: string;
};

type RadioProps<T> = {
  onChange: (value: T) => void;
  value?: T;
  options: RadioOption<T>[];
  placeholder?: string;
  name?: string;
  className?: string;
};

const Radio = <T extends string>({
  onChange,
  value,
  options,
  name = `name-${Math.random()}`,
  className,
}: RadioProps<T>): React.ReactNode => (
  <div className={`radio ${className}`}>
    {options.map(({ value: v, label }) => (
      <label key={v}>
        {label}
        <input
          name={name}
          type="radio"
          checked={v === value}
          onChange={(): void => onChange(v)}
        />
      </label>
    ))}
  </div>
);

export default Radio;
