import LabelGroups from "./_labels";

const arms = {
  name: "Arms",
  criteria: [
    {
      name: "Flexion / Extension (Left)",
      image: "/assessment-images/arm-flexion-extension-left.png",
      options: [
        {
          label: "Extension",
          values: LabelGroups.FIVE_FORTY_NINTY_PLUS,
        },
        {
          label: "Neutral",
          values: LabelGroups.NEUTRAL,
        },
        {
          label: "Flexion",
          values: LabelGroups.FIVE_FORTY_NINTY_PLUS,
        },
      ],
    },
    {
      name: "Abduction / Adduction (Left)",
      image: "/assessment-images/arm-adduction-abduction-left.png",
      options: [
        {
          label: "Abduction",
          values: LabelGroups.FIVE_FORTY_NINTY_PLUS,
        },
        {
          label: "Neutral",
          values: LabelGroups.NEUTRAL,
        },
        {
          label: "Adduction",
          values: LabelGroups.FIVE_FORTY_NINTY_PLUS,
        },
      ],
    },
    {
      name: "Flexion / Extension (Right)",
      image: "/assessment-images/arm-flexion-extension-right.png",
      options: [
        {
          label: "Flexion",
          values: LabelGroups.FIVE_FORTY_NINTY_PLUS,
        },
        {
          label: "Neutral",
          values: LabelGroups.NEUTRAL,
        },
        {
          label: "Extension",
          values: LabelGroups.FIVE_FORTY_NINTY_PLUS,
        },
      ],
    },
    {
      name: "Abduction / Adduction (Right)",
      image: "/assessment-images/arm-adduction-abduction-right.png",
      options: [
        {
          label: "Adduction",
          values: LabelGroups.FIVE_FORTY_NINTY_PLUS,
        },
        {
          label: "Neutral",
          values: LabelGroups.NEUTRAL,
        },
        {
          label: "Abduction",
          values: LabelGroups.FIVE_FORTY_NINTY_PLUS,
        },
      ],
    },
  ],
};

export default arms;
