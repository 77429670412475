import React, { useCallback } from "react";
import { StateStorageKeys } from "../../constants";
import useStickyState from "../../hooks/useStickyState";
import { Form } from "../../library";
import RemainingCharacters from "../RemainingCharacters";

export type EquipmentPrescription = string;

export const useEquipmentPrescription = () => {
  const [equipmentPrescription, setEquipmentPrescription] =
    useStickyState<EquipmentPrescription>("", StateStorageKeys.Equipment);

  const clearEquipmentPrescription = useCallback(() => {
    setEquipmentPrescription("");
  }, [setEquipmentPrescription]);

  return {
    equipmentPrescription,
    setEquipmentPrescription,
    clearEquipmentPrescription,
  };
};

type Props = {
  setEquipmentPrescription: (value: EquipmentPrescription) => void;
  equipmentPrescription: EquipmentPrescription;
};

const MAX_LENGTH = 1000;

const AssessmentEquipmentPrescription: React.FC<Props> = ({
  equipmentPrescription,
  setEquipmentPrescription,
}) => (
  <Form.Field>
    <Form.Label>Equipment prescription:</Form.Label>
    <Form.Textarea
      onChange={setEquipmentPrescription}
      value={equipmentPrescription}
      maxLength={MAX_LENGTH}
    />
    <Form.Help>
      <RemainingCharacters max={MAX_LENGTH} value={equipmentPrescription} />
    </Form.Help>
  </Form.Field>
);

export default AssessmentEquipmentPrescription;
