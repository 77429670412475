import classNames from "classnames";
import React, { PropsWithChildren, useContext } from "react";
import { ClassNameProp } from "../utils";
import MenuContext from "./MenuContext";

const Menu: React.FC<PropsWithChildren<ClassNameProp>> = ({
  children,
  className,
}) => {
  const { isOpen } = useContext(MenuContext);

  return (
    <nav
      role="navigation"
      aria-label="main navigation"
      className={classNames({ "is-active": isOpen }, "navbar-menu", className)}
    >
      {children}
    </nav>
  );
};

export default Menu;
