import classNames from "classnames";
import React from "react";
import { ClassNameProp } from "../utils";

import style from "./Loader.module.scss";

type Props = {
  size?: "small";
} & ClassNameProp;

const Loader: React.FC<Props> = ({ size, className }) => (
  <div
    title="Loading..."
    className={classNames(
      style.loader,
      {
        [style.loaderSmall]: size === "small",
      },
      className
    )}
  >
    <span className="has-background-grey" />
    <span className="has-background-grey" />
    <span className="has-background-grey" />
  </div>
);

export default Loader;
