import Nav from "./Nav";
import Brand from "./Brand";
import Menu from "./Menu";
import Start from "./Start";
import End from "./End";
import Item from "./Item";
import Header from "./Header";
import MenuToggle from "./MenuToggle";

export default Object.assign(Nav, {
  Brand,
  Menu,
  Start,
  End,
  Item,
  Header,
  MenuToggle,
});
