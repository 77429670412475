import React from "react";

import {
  Page,
  Document,
  StyleSheet,
  Font,
  Image,
  View,
  Text,
} from "@react-pdf/renderer";
import PatientDetailsPdf from "./PatientDetailsPdf";
import AssessmentResultsPdf from "./AssessmentResultsPdf";
import logo from "../../images/logo-white.jpg";
import colors from "./colors";
import { AllAssessmentData } from "../types";
import AssessmentRecommendationsPdf from "./AssessmentRecommendationsPdf";

Font.register({
  family: "Nunito",
  src: "https://fonts.gstatic.com/s/nunito/v25/XRXI3I6Li01BKofiOc5wtlZ2di8HDLshdTA3j6zbXWjgevT5.woff2",
});

const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontFamily: "Nunito",
    fontSize: 9,
  },
  columns: {
    display: "flex",
    flexDirection: "row",
  },
  image: {
    width: "auto",
    height: "auto",
  },
  pictureColumn: {
    width: 230,
    marginLeft: 10,
    paddingLeft: 20,
  },
  column50: {
    width: "45%",
  },
  logo: {
    width: 70,
    height: "auto",
    marginBottom: 40,
    alignSelf: "flex-end",
  },
  largeTag: {
    padding: 10,
    marginBottom: 20,
    marginTop: 30,
    backgroundColor: colors.primary,
    borderRadius: 6,
    color: colors.white,
    textAlign: "center",
    width: 150,
  },
});

type Props = AllAssessmentData;

const AssessmentSummaryPdf: React.FC<Props> = ({
  patientDetails,
  assessmentResults,
  assessmentCriterion,
  equipmentPrescription,
  assessmentImages,
  recommendations,
}) => {
  const score = assessmentResults.reduce((a, b) => a + b.score, 0);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.columns}>
          <View>
            <PatientDetailsPdf
              patientDetails={patientDetails}
              assessmentCriterion={assessmentCriterion}
            />

            <Text style={styles.largeTag}>Overall score: {score}</Text>

            <AssessmentResultsPdf assessmentResults={assessmentResults} />
          </View>
          <View style={styles.pictureColumn}>
            <Image style={styles.logo} src={logo} />
            {assessmentImages.map((src, idx) => (
              <Image style={styles.image} src={src} key={idx} />
            ))}
          </View>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <AssessmentRecommendationsPdf
          equipmentPrescription={equipmentPrescription}
          recommendations={recommendations}
        />
      </Page>
    </Document>
  );
};

export default AssessmentSummaryPdf;
