import classNames from "classnames";
import React, { SyntheticEvent, useCallback } from "react";

import { ClassNameProp, Variant } from "../utils";

export type SelectOption<T> = {
  value: T;
  label: string;
};

type SelectProps<T> = {
  onChange: (value: T) => void;
  value?: T;
  options: SelectOption<T>[];
  placeholder?: string;
  variant?: Variant;
  children?: React.ReactNode;
  disabled?: boolean;
  title?: string;
} & ClassNameProp;

const Select = <T extends string>({
  onChange,
  value,
  options,
  placeholder,
  className,
  variant,
  children,
  disabled,
  title,
}: SelectProps<T>): React.ReactNode => {
  const doOnChange = useCallback(
    (e: SyntheticEvent<HTMLSelectElement>) =>
      onChange(e.currentTarget.value as T),
    [onChange]
  );

  return (
    <div
      className={classNames("select is-fullwidth", className, {
        [`is-${variant}`]: variant,
      })}
    >
      <select
        title={title}
        disabled={disabled}
        onChange={doOnChange}
        placeholder={placeholder}
      >
        {children}
        {options.map(({ value: v, label }) => (
          <option key={v} value={v} selected={v === value}>
            {label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;
