import classNames from "classnames";
import React, { PropsWithChildren } from "react";
import { AssessmentSectionOption } from "../../data/assessment";
import { Button } from "../../library";
import { ClassNameProp } from "../../library/utils";
import styles from "./AssessmentOptions.module.scss";

type AssessmentButtonProps = {
  onClick: () => void;
  isSelected: boolean;
};

const AssessmentButton: React.FC<
  PropsWithChildren<AssessmentButtonProps & ClassNameProp>
> = ({ children, onClick, isSelected, className }) => (
  <Button
    className={classNames("is-fullwidth", className)}
    variant={isSelected ? "primary" : undefined}
    onClick={onClick}
    aria-selected={isSelected}
  >
    {children}
  </Button>
);

type AssessmentOptionsSelected = {
  direction?: string;
  value?: number;
};

type AssessmentOptionsProps = {
  options: AssessmentSectionOption[];
  onChange: (direction: string, value: number) => void;
  selectedOption: AssessmentOptionsSelected;
};

const InvisibleButtonSpacer: React.FC = () => (
  <span className="button is-invisible"></span>
);

const AssessmentOptions: React.FC<AssessmentOptionsProps> = ({
  options,
  onChange,
  selectedOption,
}) => {
  const buttons = options.map((o) => {
    const isSelected = o.label === selectedOption.direction;
    const onClick = () => {
      onChange(o.label, o.values[0].value);
    };

    return (
      <AssessmentButton
        className={styles.WrappedAssessmentButton}
        key={o.label}
        isSelected={isSelected}
        onClick={onClick}
      >
        {o.label}
      </AssessmentButton>
    );
  });

  const selectedOptionValues =
    options.find((o) => o.label === selectedOption.direction)?.values || [];

  const subButtons = selectedOptionValues.map((o) => {
    const isSelected = o.value === selectedOption.value;
    const onClick = () => {
      if (!selectedOption.direction)
        throw Error("Selected direction is undefined");
      onChange(selectedOption.direction, o.value);
    };
    return (
      <AssessmentButton key={o.label} isSelected={isSelected} onClick={onClick}>
        ({o.value}) {o.label}
      </AssessmentButton>
    );
  });

  return (
    <div className={styles.AssessmentControlsContainer}>
      <Button.Group
        controlClassName={styles.AssessmentControl}
        className={classNames(
          "is-flex is-justify-content-center mb-5",
          styles.AssessmentButtonGroup
        )}
      >
        {buttons}
      </Button.Group>
      <Button.Group
        controlClassName={styles.AssessmentControl}
        className={classNames(
          "is-flex is-justify-content-center",
          styles.AssessmentButtonGroup
        )}
      >
        {subButtons.length ? subButtons : <InvisibleButtonSpacer />}
      </Button.Group>
    </div>
  );
};

export default AssessmentOptions;
