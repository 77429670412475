import classNames from "classnames";
import React, { PropsWithChildren } from "react";
import { ClassNameProp } from "../utils";

export type FormErrorProps = ClassNameProp;

const Error: React.FC<PropsWithChildren<FormErrorProps>> = ({
  children,
  className,
}) => {
  return <p className={classNames("help is-danger", className)}>{children}</p>;
};

export default Error;
