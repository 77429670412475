import { AssessmentCriteria } from "./assessmentCriteria";

import pictureSitting from "../images/pen-picture-sitting-1.png";
import pictureStanding from "../images/pen-picture-standing-1.png";
import pictureStandingSide from "../images/pen-picture-standing-side-1.png";
import pictureLaying from "../images/pen-picture-laying-1.png";

type Sketch = {
  id: string;
  src: string;
};

const standingFront: Sketch = {
  src: pictureStanding,
  id: "Standing » Frontal View",
};

const standingSide: Sketch = {
  src: pictureStandingSide,
  id: "Standing » Side View",
};

const sittingSide: Sketch = {
  src: pictureSitting,
  id: "Sitting » Side View",
};

const sittingFront: Sketch = {
  src: pictureStanding,
  id: "Sitting » Frontal View",
};

const layingSide: Sketch = {
  src: pictureLaying,
  id: "Lying » Side View",
};

const layingFront: Sketch = {
  src: pictureStanding,
  id: "Lying » Frontal View",
};

const assessmentSketches = {
  [AssessmentCriteria.SITTING]: [sittingSide, sittingFront],
  [AssessmentCriteria.STANDING]: [standingSide, standingFront],
  [AssessmentCriteria.LAYING]: [layingSide, layingFront],
};

export default assessmentSketches;
