import { useEffect } from "react";

const useEscape = (onEscape?: () => void): void => {
  useEffect(() => {
    const fn = (event: KeyboardEvent): void => {
      if (event.key === "Escape") {
        onEscape?.();
      }
    };
    window.addEventListener("keydown", fn);

    // cleanup this component
    return (): void => {
      window.removeEventListener("keydown", fn);
    };
  }, [onEscape]);
};

export default useEscape;
