import { AssessmentCriteria } from "../assessmentCriteria";
import LabelGroups from "./_labels";

const legsSitting = {
  name: "Legs",
  appliesTo: [AssessmentCriteria.SITTING],
  criteria: [
    {
      name: "Flexion / Extension (Upper Left)",
      image: "/assessment-images/upper-leg-flexion-extension-left.png",
      options: [
        {
          label: "Flexion",
          values: LabelGroups.NINTY_ONETWENTY_PLUS,
        },
        {
          label: "Neutral",
          values: LabelGroups.NEUTRAL_90,
        },
        {
          label: "Extension",
          values: LabelGroups.NINTY_SIXTY_TWENTY_FIVE,
        },
      ],
    },
    {
      name: "Flexion / Extension (Upper Right)",
      image: "/assessment-images/upper-leg-flexion-extension-right.png",
      options: [
        {
          label: "Flexion",
          values: LabelGroups.NINTY_ONETWENTY_PLUS,
        },
        {
          label: "Neutral",
          values: LabelGroups.NEUTRAL_90,
        },
        {
          label: "Extension",
          values: LabelGroups.NINTY_SIXTY_TWENTY_FIVE,
        },
      ],
    },
    {
      name: "Flexion / Extension (Lower Left)",
      image: "/assessment-images/lower-leg-flexion-extension-left.png",
      options: [
        {
          label: "Extension",
          values: LabelGroups.NINTY_SIXTY_TWENTY_FIVE,
        },
        {
          label: "Neutral",
          values: LabelGroups.NEUTRAL_90,
        },
        {
          label: "Flexion",
          values: LabelGroups.NINTY_ONETWENTY_PLUS,
        },
      ],
    },
    {
      name: "Flexion / Extension (Lower Right)",
      image: "/assessment-images/lower-leg-flexion-extension-right.png",
      options: [
        {
          label: "Flexion",
          values: LabelGroups.NINTY_ONETWENTY_PLUS,
        },
        {
          label: "Neutral",
          values: LabelGroups.NEUTRAL_90,
        },
        {
          label: "Extension",
          values: LabelGroups.NINTY_SIXTY_TWENTY_FIVE,
        },
      ],
    },
    {
      name: "Abduction / Adduction (Left)",
      image: "/assessment-images/leg-adduction-abduction-left.png",
      options: [
        {
          label: "Adduction",
          values: LabelGroups.FIVE_TWENTY_SIXTY_PLUS,
        },
        {
          label: "Neutral",
          values: LabelGroups.NEUTRAL,
        },
        {
          label: "Abduction",
          values: LabelGroups.FIVE_TWENTY_SIXTY_PLUS,
        },
      ],
    },

    {
      name: "Abduction / Adduction (Right)",
      image: "/assessment-images/leg-adduction-abduction-right.png",
      options: [
        {
          label: "Adduction",
          values: LabelGroups.FIVE_TWENTY_SIXTY_PLUS,
        },
        {
          label: "Neutral",
          values: LabelGroups.NEUTRAL,
        },
        {
          label: "Abduction",
          values: LabelGroups.FIVE_TWENTY_SIXTY_PLUS,
        },
      ],
    },
    {
      name: "Rotation (Left)",
      image: "/assessment-images/lower-leg-rotation-left.png",
      options: [
        {
          label: "Left",
          values: LabelGroups.FIVE_TWENTY_SIXTY_PLUS,
        },
        {
          label: "Neutral",
          values: LabelGroups.NEUTRAL,
        },
        {
          label: "Right",
          values: LabelGroups.FIVE_TWENTY_SIXTY_PLUS,
        },
      ],
    },
    {
      name: "Rotation (Right)",
      image: "/assessment-images/lower-leg-rotation-right.png",
      options: [
        {
          label: "Left",
          values: LabelGroups.FIVE_TWENTY_SIXTY_PLUS,
        },
        {
          label: "Neutral",
          values: LabelGroups.NEUTRAL,
        },
        {
          label: "Right",
          values: LabelGroups.FIVE_TWENTY_SIXTY_PLUS,
        },
      ],
    },
  ],
};

export default legsSitting;
