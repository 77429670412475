import classNames from "classnames";
import React, { PropsWithChildren } from "react";
import { ClassNameProp } from "../utils";

export type FormFieldProps = ClassNameProp;

const Field: React.FC<PropsWithChildren<FormFieldProps>> = ({
  children,
  className,
}) => {
  return <div className={classNames("field", className)}>{children}</div>;
};

export default Field;
