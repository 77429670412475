import classNames from "classnames";
import React, { PropsWithChildren } from "react";

import { Nav } from "../../library";
import { ClassNameProp } from "../../library/utils";

import styles from "./Page.module.scss";

const Page: React.FC<PropsWithChildren<ClassNameProp>> = ({
  children,
  className,
}) => {
  return (
    <Nav>
      <div
        className={classNames(
          "is-flex is-fullwidth is-flex-direction-row-reverse is-fullheight",
          className
        )}
      >
        {children}
      </div>
    </Nav>
  );
};

const Main: React.FC<PropsWithChildren<ClassNameProp>> = ({
  children,
  className,
}) => {
  return (
    <div
      className={classNames(
        "is-flex is-fullwidth is-fullheight is-flex-direction-column is-flex-grow-1 is-relative",
        className
      )}
    >
      {children}
    </div>
  );
};

const Header: React.FC<PropsWithChildren<ClassNameProp>> = ({
  children,
  className,
}) => {
  return (
    <Nav.Header
      className={classNames(
        "is-flex flex-direction-column",
        styles.navbar,
        className
      )}
    >
      {children}
    </Nav.Header>
  );
};

const Body = React.forwardRef<HTMLDivElement, PropsWithChildren<ClassNameProp>>(
  ({ children, className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={classNames(
          "is-flex is-flex-direction-column is-fullwidth",
          className
        )}
        {...props}
      >
        {children}
      </div>
    );
  }
);

const Footer: React.FC<PropsWithChildren<ClassNameProp>> = ({
  children,
  className,
}) => {
  return <div className={classNames("", className)}>{children}</div>;
};

const Navigation: React.FC<PropsWithChildren<ClassNameProp>> = ({
  children,
  className,
}) => {
  return (
    <Nav.Menu
      className={classNames("p-4 py-5", styles.menu, "mobile-menu", className)}
    >
      {children}
    </Nav.Menu>
  );
};

export default Object.assign(Page, {
  Header,
  Main,
  Body,
  Footer,
  Navigation,
});
