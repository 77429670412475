import { Colors } from "../../constants";

export const brushColors = [
  { value: Colors.green, label: "Green" },
  { value: Colors.yellow, label: "Yellow" },
  { value: Colors.orange, label: "Orange" },
  { value: Colors.red, label: "Red" },
  { value: "#2c47e7", label: "Blue" },
] as const;

export const defaultBrushColor = brushColors[0];

export const brushSizes = [
  { value: 1, label: "S" },
  { value: 2, label: "M" },
  { value: 4, label: "L" },
] as const;

export const defaultBrushSize = brushSizes[1];
