import classNames from "classnames";
import React, { PropsWithChildren } from "react";
import { ClassNameProp } from "../utils";

const Item: React.FC<PropsWithChildren<ClassNameProp>> = ({
  className,
  children,
}) => {
  const cls = classNames("navbar-item", className);

  return <div className={cls}>{children}</div>;
};

export default Item;
