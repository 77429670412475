import { createContext } from "react";

const MenuContext = createContext({
  isOpen: false,
  toggle: (state?: boolean): void => {
    // default
  },
});

export default MenuContext;
