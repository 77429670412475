import React, { PropsWithChildren, useContext } from "react";

import SwitchContext from "./SwitchContext";

type Props = {
  caseId: string | number;
};

const Case: React.FC<PropsWithChildren<Props>> = ({ caseId, children }) => {
  const { caseId: selectedCaseId } = useContext(SwitchContext);

  return caseId === selectedCaseId ? <>{children}</> : null;
};

export default Case;
