import classNames from "classnames";
import React from "react";
import { Colors } from "../../constants";
import { Tag } from "../../library";
import formatAssessmentCriterion from "../../utils/formatAssessmentCriterion";

import { AssessmentResult } from "../Assessment/useAssessment";
import TruncateText from "../TruncateText";
import { AllAssessmentData } from "../types";

import styles from "./AssessmentSummary.module.scss";

type AssessmentResultsTableProps = {
  assessmentResults: AssessmentResult[];
};

const scoreToColour = [
  [Colors.green, "#FFFFFF"],
  [Colors.yellow, "#000000"],
  [Colors.orange, "#FFFFFF"],
  [Colors.red, "#FFFFFF"],
];

const getColorStylesForScore = (score: number) => {
  const [backgroundColor, color] = scoreToColour[score] || [
    "#000000",
    "#FFFFFF",
  ];
  return {
    backgroundColor,
    color,
  };
};

const AssessmentResultsTable: React.FC<AssessmentResultsTableProps> = ({
  assessmentResults,
}) => {
  const rows = assessmentResults
    .map((a) => {
      return [
        <tr key={`${a.name}`}>
          <th>{a.name}</th>
          <th className="has-text-right">
            <Tag>Total: {a.score}</Tag>
          </th>
          <th></th>
        </tr>,

        ...a.criteria.map((c) => (
          <tr key={`${a.name}-${c.name}`}>
            <td>{c.name}</td>
            <td className="has-text-right">
              {c.data && (
                <Tag style={getColorStylesForScore(c.data.value)}>
                  {c.data.value}
                </Tag>
              )}
            </td>
            <td>{c.data?.direction}</td>
          </tr>
        )),
        <tr className={styles.spacerRow} key={`${a.name}-spacer`}>
          <td colSpan={3}>&nbsp;</td>
        </tr>,
      ];
    })
    .flat();

  return (
    <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
      <thead className="has-background-grey-lighter">
        <tr>
          <th>Section</th>
          <th>Score</th>
          <th>Movement direction</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  );
};

type AssessmentSummaryProps = Pick<
  AllAssessmentData,
  "patientDetails" | "assessmentResults" | "assessmentCriterion"
>;

const AssessmentSummary: React.FC<AssessmentSummaryProps> = ({
  patientDetails,
  assessmentResults,
  assessmentCriterion,
}) => {
  const score = assessmentResults.reduce((a, b) => a + b.score, 0);

  return (
    <div className="p-3">
      <div className="columns">
        <div className="column">
          <table className={classNames("table", styles.detailsTable)}>
            <tbody>
              <tr>
                <th>Patient name:</th>
                <td> {patientDetails.name}</td>
              </tr>
              <tr>
                <th>Therapist:</th>
                <td>{patientDetails.therapist}</td>
              </tr>
              <tr>
                <th>Location:</th>
                <td>{patientDetails.location}</td>
              </tr>
              <tr>
                <th>Assessment:</th>
                <td>{formatAssessmentCriterion(assessmentCriterion)}</td>
              </tr>

              <tr>
                <th>Date:</th>
                <td>{patientDetails.date}</td>
              </tr>

              {patientDetails.notes && (
                <tr>
                  <th>Notes:</th>
                  <td>
                    <TruncateText>{patientDetails.notes}</TruncateText>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="column">
          <Tag size="large" variant="primary">
            Overall score: {score}
          </Tag>
        </div>
      </div>

      <AssessmentResultsTable assessmentResults={assessmentResults} />
    </div>
  );
};

export default AssessmentSummary;
