import React, { PropsWithChildren } from "react";
import { Icon } from "../../library";
import classNames from "classnames";
import styles from "./Sketch.module.scss";
import { brushColors, brushSizes } from "./constants";

type ControlProps = {
  brushColor: string;
  onChangeBrushColor: (c: string) => void;
  brushRadius: number;
  onChangeBrushRadius: (r: number) => void;
  onClear: () => void;
  onUndo: () => void;
};

type ButtonProps = {
  onClick: () => void;
  style?: React.CSSProperties;
  title?: string;
  isSelected?: boolean;
};

const Button: React.FC<PropsWithChildren<ButtonProps>> = ({
  children,
  onClick,
  style,
  title,
  isSelected = false,
}) => (
  <button
    title={title}
    onClick={onClick}
    style={style}
    className={classNames("is-small button m-1", styles.canvasButton, {
      [styles.isSelected]: isSelected,
    })}
  >
    {children}
  </button>
);

const ControlContainer: React.FC<PropsWithChildren> = ({ children }) => (
  <div className="is-flex is-flex-direction-column mb-5">{children}</div>
);

const Controls: React.FC<ControlProps> = ({
  onChangeBrushRadius,
  brushRadius,
  onChangeBrushColor,
  brushColor,
  onUndo,
  onClear,
}) => {
  return (
    <nav className="is-flex is-flex-direction-column is-justify-content-space-between">
      <ControlContainer>
        <ControlContainer>
          {brushSizes.map((b) => (
            <Button
              isSelected={b.value === brushRadius}
              key={b.value}
              onClick={() => onChangeBrushRadius(b.value)}
            >
              {b.label}
            </Button>
          ))}
        </ControlContainer>

        <ControlContainer>
          {brushColors.map((c) => (
            <Button
              key={c.value}
              style={{ backgroundColor: c.value, border: "none" }}
              title={c.label}
              onClick={() => onChangeBrushColor(c.value)}
              isSelected={c.value === brushColor}
            ></Button>
          ))}
        </ControlContainer>
      </ControlContainer>

      <ControlContainer>
        <Button onClick={onUndo}>
          <Icon icon="undo" />
        </Button>
        <Button onClick={onClear}>
          <Icon icon="trash" />
        </Button>
      </ControlContainer>
    </nav>
  );
};

export default Controls;
