import classNames from "classnames";
import React, { PropsWithChildren } from "react";
import { ClassNameProp } from "../utils";

export type Props = ClassNameProp;

const Help: React.FC<PropsWithChildren<Props>> = ({ children, className }) => {
  return <p className={classNames("help", className)}>{children}</p>;
};

export default Help;
