const toImageDataUrl = (
  drawingCanvas: HTMLCanvasElement,
  gridCanvas: HTMLCanvasElement
) => {
  // Get a reference to the "drawing" layer of the canvas
  const canvasToExport = drawingCanvas;
  const context = canvasToExport.getContext("2d");

  if (!context) throw Error("Context is not initialized for canvas");

  //cache height and width
  const width = canvasToExport.width;
  const height = canvasToExport.height;

  //get the current ImageData for the canvas
  const storedImageData = context.getImageData(0, 0, width, height);

  //store the current globalCompositeOperation
  const compositeOperation = context.globalCompositeOperation;

  //set to draw behind current content
  context.globalCompositeOperation = "destination-over";

  //draw the bg image, or grid, they are both draw to the "grid" canvas layer
  context.drawImage(gridCanvas, 0, 0);

  const imageData = canvasToExport.toDataURL("image/png");

  //clear the canvas
  context.clearRect(0, 0, width, height);

  //restore it with original / cached ImageData
  context.putImageData(storedImageData, 0, 0);

  //reset the globalCompositeOperation to what it was
  context.globalCompositeOperation = compositeOperation;

  return imageData;
};

export default toImageDataUrl;
