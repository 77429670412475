import React from "react";
import { Modal } from "../../library";
import AssessmentCriteriaSelection, {
  AssessmentCriterion,
} from "../AssessmentCriteriaSelection";

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  setAssessmentCriterion: (criterion: AssessmentCriterion) => void;
};

const NewAssessmentModal: React.FC<Props> = ({
  closeModal,
  isOpen,
  setAssessmentCriterion,
}) => (
  <Modal aria-label="New assessment" isOpen={isOpen} onClose={closeModal}>
    {isOpen && ( // remount component each time, bit of a hack to reset internal state of AssessmentCriteriaSelection
      <AssessmentCriteriaSelection>
        <Modal.Header>New assessment</Modal.Header>
        <Modal.Body>
          <AssessmentCriteriaSelection.Options />
          <AssessmentCriteriaSelection.Details />
        </Modal.Body>
        <Modal.Footer>
          <AssessmentCriteriaSelection.Button
            onClick={(v) => {
              setAssessmentCriterion(v);
              closeModal();
            }}
          >
            Start
          </AssessmentCriteriaSelection.Button>
        </Modal.Footer>
      </AssessmentCriteriaSelection>
    )}
  </Modal>
);

export default NewAssessmentModal;
