import React, { useCallback, useMemo } from "react";
import { useSwipeable } from "react-swipeable";
import { assessments } from "../../data";
import { Form, Nav } from "../../library";
import useAssessment from "./useAssessment";
import AssessmentControls from "../AssessmentControls";
import AssessmentImage from "../AssessmentImage";
import AssesmentMenu from "../AssessmentMenu";
import AssessmentOptions from "../AssessmentOptions";
import CompleteAssessment from "../CompleteAssessmentModal";
import Page from "../Page";
import PatientDetails, { usePatient } from "../PatientDetails";
import { AssessmentCriterion } from "../AssessmentCriteriaSelection";
import AssessmentSummary from "../AssessmentSummary";
import formatAssessmentCriterion from "../../utils/formatAssessmentCriterion";

import Sketch from "../Sketch";
import assessmentSketches from "../../data/assessmentSketch";
import useSketch from "./useSketch";
import useNextPrev from "../../hooks/useNextPrev";
import AssessmentRecommendations, {
  useRecommendations,
} from "../AssessmentRecommendations";
import AssessmentSummaryPdf from "../AssessmentSummaryPdf";
import { PDFViewer } from "@react-pdf/renderer";
import features from "../../features";
import AssessmentEquipmentPrescription, {
  useEquipmentPrescription,
} from "../EquipmentPrescription";

enum Pages {
  PatientDetails = "PatientDetails",
  Assessment = "Assessment",
  AssessmentSummary = "AssessmentSummary",
  AssessmentDetails = "AssessmentDetails",
  AssessmentSketch = "AssessmentSketch",
  PDFViewer = "PDFViewer",
}

type AssessmentProps = {
  setAssessmentCriterion: (id: AssessmentCriterion | null) => void;
  assessmentCriterion: AssessmentCriterion;
};

type ChildPage = {
  id: string;
  label: string;
  content: React.ReactElement;
};

type ParentPage = {
  id: string;
  label: string;
  icon?: string;
  disabled?: boolean;
  content?: React.ReactElement;
  children?: ChildPage[];
};

const makeId = (...parts: string[]) => parts.join(":");

const Assessment: React.FC<AssessmentProps> = ({
  assessmentCriterion,
  setAssessmentCriterion,
}) => {
  const filteredAssessments = assessments.filter(
    (a) => !a.appliesTo || a.appliesTo?.includes(assessmentCriterion.criterion)
  );

  const {
    assessmentResults,
    clearAssessmentData,
    setAssessmentData,
    findAssessmentData,
  } = useAssessment(filteredAssessments);

  const { patient, updatePatient, clearPatient } = usePatient();
  const { recommendations, updateRecommendations, clearRecommendations } =
    useRecommendations();
  const {
    equipmentPrescription,
    setEquipmentPrescription,
    clearEquipmentPrescription,
  } = useEquipmentPrescription();

  const { savedSketches, setSketchData, clearSketchData } = useSketch();

  const sketches = assessmentSketches[assessmentCriterion.criterion];

  const sketchImages = sketches.map((s) => savedSketches[s.id]?.image ?? s.src);

  const pages = useMemo(() => {
    const pages: ParentPage[] = [
      {
        id: Pages.PDFViewer,
        label: "PDF Viewer",
        icon: "file-pdf",
        disabled: !features.pdfviewer,
        content: (
          <PDFViewer height={800} showToolbar={false}>
            <AssessmentSummaryPdf
              equipmentPrescription={equipmentPrescription}
              recommendations={recommendations}
              assessmentImages={sketchImages}
              assessmentCriterion={assessmentCriterion}
              patientDetails={patient}
              assessmentResults={assessmentResults}
            />
          </PDFViewer>
        ),
      },
      {
        id: Pages.PatientDetails,
        label: "Patient details",
        icon: "user",
        content: (
          <PatientDetails updatePatient={updatePatient} patient={patient} />
        ),
      },
      ...filteredAssessments.map((a) => ({
        id: a.name,
        label: a.name,
        children: a.criteria.map(({ name, image, options }) => ({
          id: name,
          label: name,
          content: (
            <>
              <span>{/*flex spacer to center image*/}</span>
              <AssessmentImage src={image} />
              <AssessmentOptions
                selectedOption={findAssessmentData(a.name, name) || {}}
                onChange={(direction, value) =>
                  setAssessmentData(
                    { main: a.name, sub: name },
                    direction,
                    value
                  )
                }
                options={options}
              />
            </>
          ),
        })),
      })),
      {
        id: Pages.AssessmentSketch,
        icon: "pencil",
        label: "Assessment Sketch",
        children: sketches.map((s, idx) => ({
          id: idx.toString(),
          label: s.id,
          content: (
            <Sketch
              key={s.id}
              saveData={savedSketches[s.id]?.data}
              image={s.src}
              onChange={(data) => setSketchData(s.id, data)}
            />
          ),
        })),
      },
      {
        id: Pages.AssessmentSummary,
        icon: "file",
        label: "Assessment Summary",
        content: (
          <AssessmentSummary
            assessmentCriterion={assessmentCriterion}
            patientDetails={patient}
            assessmentResults={assessmentResults}
          />
        ),
      },
      {
        id: Pages.AssessmentDetails,
        icon: "comment-medical",
        label: "Recommendations",
        content: (
          <Form className="p-3">
            <AssessmentRecommendations
              recommendations={recommendations}
              updateRecommendations={updateRecommendations}
            />
            <AssessmentEquipmentPrescription
              equipmentPrescription={equipmentPrescription}
              setEquipmentPrescription={setEquipmentPrescription}
            />
          </Form>
        ),
      },
    ];
    return pages.filter((p) => !p.disabled);
  }, [
    equipmentPrescription,
    setEquipmentPrescription,
    recommendations,
    updateRecommendations,
    findAssessmentData,
    setAssessmentData,
    assessmentCriterion,
    assessmentResults,
    filteredAssessments,
    patient,
    updatePatient,
    sketches,
    savedSketches,
    sketchImages,
    setSketchData,
  ]);

  const flattenedPages = pages.flatMap(
    (p) =>
      p.children?.map((c) => ({
        ...c,
        id: makeId(p.id, c.id),
        label: `${p.label} » ${c.label}`,
      })) ?? [p]
  );

  const pageIds = flattenedPages.map((p) => p.id);

  const {
    next,
    prev,
    reset: resetPage,
    current,
    setCurrent,
    isLast,
  } = useNextPrev(pageIds);

  const reset = useCallback(
    (isExitAssessment: boolean) => {
      if (isExitAssessment) {
        clearPatient();
        clearRecommendations();
        clearEquipmentPrescription();
      }
      clearAssessmentData();
      clearSketchData();
      resetPage();
      setAssessmentCriterion(null);
    },
    [
      clearEquipmentPrescription,
      clearRecommendations,
      clearPatient,
      clearAssessmentData,
      clearSketchData,
      resetPage,
      setAssessmentCriterion,
    ]
  );

  const exitAssessment = useCallback(() => reset(true), [reset]);

  const completeAnotherAssessment = useCallback(
    (assessment: AssessmentCriterion) => {
      reset(false);
      setAssessmentCriterion(assessment); // set next assessment criteria
    },
    [reset, setAssessmentCriterion]
  );

  const completeAssessmentButton = (
    <CompleteAssessment
      recommendations={recommendations}
      equipmentPrescription={equipmentPrescription}
      assessmentCriterion={assessmentCriterion}
      patientDetails={patient}
      assessmentResults={assessmentResults}
      exitAssessment={exitAssessment}
      completeAnotherAssessment={completeAnotherAssessment}
      assessmentImages={sketchImages}
    />
  );

  const currentPage = flattenedPages.find((p) => p.id === current);

  const isSketchScreen = current.startsWith(Pages.AssessmentSketch);

  const swipeHandlers = useSwipeable({
    onSwipedRight: prev,
    onSwipedLeft: next,
    preventScrollOnSwipe: true,
    // don't enable on the sketch screens as we need to be able to swipe
    // really we should prevent the touch events bubbling out of the sketch elements, but this
    // is a simple enough work around
    trackTouch: !isSketchScreen,
  });

  return (
    <Page className="has-background-white">
      <Page.Main>
        <Page.Header className="has-background-white">
          <Nav.MenuToggle />
          <Nav.Item className="has-text-weight-bold is-size-5-tablet is-flex-direction-column is-align-items-start">
            <h1 className="is-size-7 is-truncated">
              {formatAssessmentCriterion(assessmentCriterion)}
            </h1>
            <h2 className="has-text-weight-bold">{currentPage?.label}</h2>
          </Nav.Item>
        </Page.Header>
        <Page.Body
          {...swipeHandlers}
          className="is-justify-content-space-between is-flex-grow-1 has-scroll-y px-1 py-3"
        >
          {currentPage?.content}
        </Page.Body>
        <Page.Footer className="has-border-top p-4 pb-5 is-flex is-justify-content-space-between">
          <AssessmentControls.Prev onPrev={prev} />
          {isLast ? (
            completeAssessmentButton
          ) : (
            <AssessmentControls.Next onNext={next} />
          )}
        </Page.Footer>
      </Page.Main>
      <Page.Navigation className="has-background-feature has-text-white">
        <div className="is-size-5 has-text-weight-semibold mb-4 is-hidden-touch">
          CABA Assessment
        </div>
        <div className="is-flex is-flex-grow-1 has-scroll-y">
          <AssesmentMenu
            onSelect={(...path) => setCurrent(makeId(...path))}
            currentItem={current.split(":")}
            items={pages}
          />
        </div>
        {completeAssessmentButton}
      </Page.Navigation>
    </Page>
  );
};

export default Assessment;
